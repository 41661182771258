const initState = {
	current: 'es',
};

const languageReducer = (state = initState, action) => {
	switch (action.type) {
		case "CHANGE_LANGUAGE":
			return {
				...state,
				current: action.payload
			};
		default:
			return state;
	}
};

export default languageReducer;
