import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import Loadable from "react-loadable";

const loading = () => (
	<div className="animated fadeIn pt-3 text-center">Loading...</div>
);

const DefaultLayout = Loadable({
	loader: () => import("./containers/DefaultLayout/DefaultLayout"),
	loading,
});

function isAuthenticated(auth, user) {
	return auth.uid;
}

const ProtectedRoute = ({ component: Component, auth, user, ...rest }) => {
	//  console.log( auth, user)
	return (
		<Route
			{...rest}
			render={(props) => {
				if (isAuthenticated(auth, user)) {
					return <DefaultLayout {...props} />;
				} else {
					return (
						<Redirect
							to={{
								pathname: "/login",
								state: {
									from: props.location,
								},
							}}
						/>
					);
				}
				//return <DefaultLayout {...props} />;
			}}
		/>
	);
};

export default withRouter(ProtectedRoute);
