import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import { reduxFirestore, firestoreReducer } from "redux-firestore";
import { firebaseReducer, getFirebase } from "react-redux-firebase";
import firebase from "./firebaseConfig";
import authReducer from "../store/reducers/authReducer";
import languageReducer from "../store/reducers/languageReducer";
import thunk from "redux-thunk";

firebase.firestore();

const rootReducer = combineReducers({
	firebase: firebaseReducer,
	firestore: firestoreReducer,
	auth: authReducer,
	languageState: languageReducer,
});

const createStoreWithFirebase = compose(
	applyMiddleware(thunk.withExtraArgument(getFirebase)),
	reduxFirestore(firebase)
)(createStore);

const initialState = {};

export default createStoreWithFirebase(rootReducer, initialState);
