import { toast } from "react-toastify";
import { ToastMessage } from "../../components/ToastMessage/ToastMessage";
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';


export const signIn = (credentials) => {
    return (dispatch) => {
        firebase.firestore().collection('users').where('email', '==', credentials.email).get().then(query => {
            console.log(query.size, query)
            if (query.size == 0) {
                toast.error(ToastMessage('cil-x-circle', "You have no access to this CMS"));
                return;
            }
            firebase.auth().signInWithEmailAndPassword(credentials.email, credentials.password)
                .then((data) => {

                    dispatch({ type: 'LOGIN_SUCCESS', user: data })
                    setTimeout(() => {
                        toast.success(ToastMessage('cil-check-circle', 'Logged in!'));
                    }, 1000);
                })
                .catch((err) => {
                    console.error(err)
                    dispatch({ type: 'LOGIN_ERROR', message: err.message })
                    if (err.code === 'auth/wrong-password') {
                        toast.error(ToastMessage('cil-x-circle', "Wrong password, please try again or click forgot password to reset it"));
                    } else {
                        toast.error(ToastMessage('cil-x-circle', "Oops! Something went wrong, try again later!"));
                    }
                });
        })

    }
}

export const logout = () => {
    return (dispatch) => {
        firebase.auth().signOut()
            .then(() => {
                dispatch({ type: 'LOGOUT_SUCCESS' })
                toast.success(ToastMessage('cil-check-circle', "Logged out!"));
            })
            .catch(err => {
                console.error(err)
                dispatch({ type: 'LOGOUT_ERROR' })
                toast.error(ToastMessage('cil-x-circle', "Oops! Something went wrong, try again later!"));
            })
    }
}

export const verifyAuth = () => {
    return (dispatch) => {
        firebase.auth().onAuthStateChanged(user => {
            if (user !== null) {
                dispatch({ type: 'LOGIN_SUCCESS', user })
            }
        })
    }
}

export const sendResetPasswordEmail = (email) => {
    return dispatch => {
        firebase.auth().sendPasswordResetEmail(email)
            .then(data => {
                // console.log('Email sent')
                toast.success(ToastMessage('cil-check-circle', "Email sent! Check your inbox to reset your password"));
            })
            .catch(err => {
                console.error(err)
                toast.error(ToastMessage('cil-x-circle', "Oops! Something went wrong, try again later!"));
            }
            )
    }
}

export const resetPassword = (code, newPassword) => {
    return dispatch => {
        firebase.auth().confirmPasswordReset(code, newPassword)
            .then(data => {
                // console.log('Done')
                setTimeout(() => {
                    toast.success(ToastMessage('cil-check-circle', 'Password reseted successfully, try to login now!'));
                }, 200);
            })
            .catch(err => {
                console.error(err)
                toast.error(ToastMessage('cil-x-circle', "Oops! Something went wrong, try again later!"));
            }
            )
    }
}