// import React from 'react';
import Loadable from 'react-loadable';
import DefaultLayout from '../containers/DefaultLayout/DefaultLayout';
import Login from '../views/Login/Login';
import Loading from '../components/Loading/Loading';

const Dashboard = Loadable({
	loader: () => import('../views/Dashboard/Dashboard'),
	loading: Loading,
});

const EditContent = Loadable({
	loader: () => import('../views/EditContent/EditContent'),
	loading: Loading,
});

const EditHeader = Loadable({
	loader: () => import('../views/EditContent/EditHeader/EditHeader'),
	loading: Loading,
});

const EditHome = Loadable({
	loader: () => import('../views/EditContent/EditHome/EditHome'),
	loading: Loading,
});

const EditSlider = Loadable({
	loader: () => import('../views/EditContent/EditHome/EditSlider/EditSlider'),
	loading: Loading,
});
const EditCultureSection = Loadable({
	loader: () =>
		import(
			'../views/EditContent/EditHome/EditCultureSection/EditCultureSection'
		),
	loading: Loading,
});
const EditInfoSection = Loadable({
	loader: () =>
		import('../views/EditContent/EditHome/EditInfoSection/EditInfoSection'),
	loading: Loading,
});
const EditBusinessSection = Loadable({
	loader: () =>
		import(
			'../views/EditContent/EditHome/EditBusinessSection/EditBusinessSection'
		),
	loading: Loading,
});
const EditAchievements = Loadable({
	loader: () =>
		import('../views/EditContent/EditHome/Editachievements/EditAchievements'),
	loading: Loading,
});
const EditVisionSlider = Loadable({
	loader: () =>
		import('../views/EditContent/EditVision/EditVisionSlider/EditVisionSlider'),
	loading: Loading,
});

const EditVision = Loadable({
	loader: () => import('../views/EditContent/EditVision/EditVision'),
	loading: Loading,
});

const EditFirstSection = Loadable({
	loader: () =>
		import('../views/EditContent/EditVision/EditFirstSection/EditFirstSection'),
	loading: Loading,
});
const EditSecondSection = Loadable({
	loader: () =>
		import(
			'../views/EditContent/EditVision/EditSecondSection/EditSecondSection'
		),
	loading: Loading,
});

const EditBussinessSlider = Loadable({
	loader: () =>
		import(
			'../views/EditContent/EditOurBussiness/EditBussinessSlider/EditBussinessSlider'
		),
	loading: Loading,
});
const EditKalea = Loadable({
	loader: () =>
		import('../views/EditContent/EditOurBussiness/EditKalea/EditKalea'),
	loading: Loading,
});
const EditMareah = Loadable({
	loader: () =>
		import('../views/EditContent/EditOurBussiness/EditMareah/EditMareah'),
	loading: Loading,
});
const EditRafay = Loadable({
	loader: () =>
		import('../views/EditContent/EditOurBussiness/EditRafay/EditRafay'),
	loading: Loading,
});
const EditGrupoGabanes = Loadable({
	loader: () =>
		import(
			'../views/EditContent/EditOurBussiness/EditGrupoGabanes/EditGrupoGabanes'
		),
	loading: Loading,
});
const EditGrupoGuaparo = Loadable({
	loader: () =>
		import(
			'../views/EditContent/EditOurBussiness/EditGrupoGuaparo/EditGrupoGuaparo'
		),
	loading: Loading,
});

const EditOurBussiness = Loadable({
	loader: () =>
		import('../views/EditContent/EditOurBussiness/EditOurBussiness'),
	loading: Loading,
});
const EditCareerSlider = Loadable({
	loader: () =>
		import(
			'../views/EditContent/EditCareers/EditCareersSlider/EditCareersSlider'
		),
	loading: Loading,
});
const EditCareersCultureWeRepresent = Loadable({
	loader: () =>
		import(
			'../views/EditContent/EditCareers/EditCareersCultureWeRepresent/EditCareersCultureWeRepresent'
		),
	loading: Loading,
});
const EditCareersWhenClearGoal = Loadable({
	loader: () =>
		import(
			'../views/EditContent/EditCareers/EditCareersWhenClearGoal/EditCareersWhenClearGoal'
		),
	loading: Loading,
});
const EditCareersYourTalents = Loadable({
	loader: () =>
		import(
			'../views/EditContent/EditCareers/EditCareersYourTalents/EditCareersYourTalents'
		),
	loading: Loading,
});
const EditCareersSuccessTogether = Loadable({
	loader: () =>
		import(
			'../views/EditContent/EditCareers/EditCareersSuccessTogether/EditCareersSuccessTogether'
		),
	loading: Loading,
});

const EditCareers = Loadable({
	loader: () => import('../views/EditContent/EditCareers/EditCareers'),
	loading: Loading,
});

//Othes
const EditDifferences = Loadable({
	loader: () =>
		import('../views/EditContent/EditHome/EditOurDifferences/EditDifferences'),
	loading: Loading,
});

const EditAllies = Loadable({
	loader: () => import('../views/EditContent/EditHome/EditAllies/EditAllies'),
	loading: Loading,
});

const EditClientComments = Loadable({
	loader: () =>
		import(
			'../views/EditContent/EditHome/EditClientComments/EditClientComments'
		),
	loading: Loading,
});

const EditYouNeed = Loadable({
	loader: () => import('../views/EditContent/EditHome/EditYouNeed/EditYouNeed'),
	loading: Loading,
});

const EditExpertOpinions = Loadable({
	loader: () =>
		import(
			'../views/EditContent/EditHome/EditExpertOpinions/EditExpertOpinions'
		),
	loading: Loading,
});

const EditContactSection = Loadable({
	loader: () => import('../views/EditContent/EditHome/EditContact/EditContact'),
	loading: Loading,
});

const EditOurProducts = Loadable({
	loader: () => import('../views/EditContent/EditOurProducts/EditOurProducts'),
	loading: Loading,
});

const EditPersons = Loadable({
	loader: () =>
		import('../views/EditContent/EditOurProducts/EditPersons/EditPersons'),
	loading: Loading,
});
const EditCars = Loadable({
	loader: () =>
		import('../views/EditContent/EditOurProducts/EditCars/EditCars'),
	loading: Loading,
});
const EditWhealth = Loadable({
	loader: () =>
		import('../views/EditContent/EditOurProducts/EditWhealth/EditWhealth'),
	loading: Loading,
});
const EditBonds = Loadable({
	loader: () =>
		import('../views/EditContent/EditOurProducts/EditBonds/EditBonds'),
	loading: Loading,
});

const ContactUsSubmitions = Loadable({
	loader: () => import('../views/ContactUsSubmitions/ContactUsSubmitions'),
	loading: Loading,
});

const EditSliderImages = Loadable({
	loader: () =>
		import(
			'../views/EditContent/EditOurProducts/EditSliderImages/EditSliderImages'
		),
	loading: Loading,
});
const EditContact = Loadable({
	loader: () => import('../views/EditContent/EditContact/EditContact'),
	loading: Loading,
});
const EditJblExperience = Loadable({
	loader: () =>
		import('../views/EditContent/EditJblExperience/EditJblExperience'),
	loading: Loading,
});
const EditFooter = Loadable({
	loader: () => import('../views/EditContent/EditFooter/EditFooter'),
	loading: Loading,
});
const EditIcons = Loadable({
	loader: () => import('../views/EditContent/EditIcons/EditIcons'),
	loading: Loading,
});
const EditSocials = Loadable({
	loader: () => import('../views/EditContent/EditSocials/EditSocials'),
	loading: Loading,
});
const EditInsuranceForms = Loadable({
	loader: () =>
		import('../views/EditContent/EditInsuranceForms/EditInsuranceForms'),
	loading: Loading,
});

const FormsSubmitions = Loadable({
	loader: () => import('../views/formsSubmitions/formsSubmitions'),
	loading: Loading,
});

const EditInfoSection2 = Loadable({
	loader: () =>
		import(
			'../views/EditContent/EditOurBussiness/EditInfoSection/EditInfoSection2'
		),
	loading: Loading,
});

const routes = [
	{ path: '/', name: 'Home', component: DefaultLayout, exact: true },
	{ path: '/login', name: 'Login', component: Login, exact: true },
	{ path: '/dashboard', name: 'Dashboard', component: Dashboard },
	{
		path: '/edit-content',
		name: 'Edit Content',
		component: EditContent,
		exact: true,
	},
	// Header
	{
		path: '/edit-content/header',
		name: 'Header',
		component: EditHeader,
		exact: true,
	},
	// Home
	{
		path: '/edit-content/home',
		name: 'Home',
		component: EditHome,
		exact: true,
	},
	{
		path: '/edit-content/home/slider',
		name: 'slider',
		component: EditSlider,
	},
	{
		path: '/edit-content/home/cultureSection',
		name: 'Culture Section',
		component: EditCultureSection,
	},
	{
		path: '/edit-content/home/InfoSection',
		name: 'Info Section',
		component: EditInfoSection,
	},
	{
		path: '/edit-content/home/businessSection',
		name: 'Business Section',
		component: EditBusinessSection,
	},
	{
		path: '/edit-content/home/achievements',
		name: 'Achivements Section',
		component: EditAchievements,
	},
	{
		path: '/edit-content/careers/cultureWeRepresent',
		name: 'Careers Culture We Represent',
		component: EditCareersCultureWeRepresent,
		exact: true,
	},
	{
		path: '/edit-content/careers/whenClearGoal',
		name: 'Careers When Clear Goal',
		component: EditCareersWhenClearGoal,
		exact: true,
	},
	{
		path: '/edit-content/careers/yourTalents',
		name: 'Careers Your Talents',
		component: EditCareersYourTalents,
		exact: true,
	},
	{
		path: '/edit-content/careers/successTogether',
		name: 'Careers Your Talents',
		component: EditCareersSuccessTogether,
		exact: true,
	},
	{
		path: '/edit-content/home/our-differences',
		name: 'Our differences',
		component: EditDifferences,
	},
	{
		path: '/edit-content/home/allies',
		name: 'Allies Section',
		component: EditAllies,
	},
	{
		path: '/edit-content/home/your-needs',
		name: 'What you need',
		component: EditYouNeed,
	},
	{
		path: '/edit-content/home/experts',
		name: 'Experts opinions',
		component: EditExpertOpinions,
	},
	{
		path: '/edit-content/home/contactUs',
		name: 'Contact Section Home',
		component: EditContactSection,
	},
	//Vision
	{
		path: '/edit-content/vision',
		name: 'Vision',
		component: EditVision,
		exact: true,
	},
	{
		path: '/edit-content/vision/slider',
		name: 'Vision Slider',
		component: EditVisionSlider,
		exact: true,
	},
	{
		path: '/edit-content/vision/firstSection',
		name: 'First Section',
		component: EditFirstSection,
		exact: true,
	},
	{
		path: '/edit-content/vision/secondSection',
		name: 'Second Section',
		component: EditSecondSection,
		exact: true,
	},
	//Our Bussiness
	{
		path: '/edit-content/ourBussiness',
		name: 'Our Bussiness',
		component: EditOurBussiness,
		exact: true,
	},
	{
		path: '/edit-content/ourBussiness/kalea',
		name: 'Our Bussiness Kalea',
		component: EditKalea,
		exact: true,
	},
	{
		path: '/edit-content/ourBussiness/mareah',
		name: 'Our Bussiness Mareah',
		component: EditMareah,
		exact: true,
	},
	{
		path: '/edit-content/ourBussiness/rafay',
		name: 'Our Bussiness Rafay',
		component: EditRafay,
		exact: true,
	},
	{
		path: '/edit-content/ourBussiness/grupoGabanes',
		name: 'Our Bussiness Grupo Gabanes',
		component: EditGrupoGabanes,
		exact: true,
	},
	{
		path: '/edit-content/ourBussiness/grupoGuaparo',
		name: 'Our Bussiness Grupo Guaparo',
		component: EditGrupoGuaparo,
		exact: true,
	},
	{
		path: '/edit-content/ourBussiness/slider',
		name: 'Our Bussiness Slider',
		component: EditBussinessSlider,
		exact: true,
	},
	//Careers
	{
		path: '/edit-content/careers',
		name: 'Careers',
		component: EditCareers,
		exact: true,
	},
	{
		path: '/edit-content/careers/slider',
		name: 'Careers Slider',
		component: EditCareerSlider,
		exact: true,
	},
	// Nuestros productos
	{
		path: '/edit-content/our-products',
		name: 'Our Products',
		component: EditOurProducts,
		exact: true,
	},
	{
		path: '/edit-content/our-products/persons',
		name: 'Personal insurance',
		component: EditPersons,
		exact: true,
	},
	{
		path: '/edit-content/our-products/vehicles',
		name: 'Vehicles insurance',
		component: EditCars,
		exact: true,
	},
	{
		path: '/edit-content/our-products/whealth',
		name: 'Whealth insurance',
		component: EditWhealth,
		exact: true,
	},
	{
		path: '/edit-content/our-products/bonds',
		name: 'Bonds insurance',
		component: EditBonds,
		exact: true,
	},
	{
		path: '/edit-content/our-products/slider-images',
		name: 'Slider images',
		component: EditSliderImages,
		exact: true,
	},
	{
		path: '/edit-content/contact',
		name: 'Contact Page',
		component: EditContact,
		exact: true,
	},
	{
		path: '/edit-content/jbl-experience',
		name: 'Experience JBL Page',
		component: EditJblExperience,
		exact: true,
	},
	{
		path: '/edit-content/footer',
		name: 'Footer Content',
		component: EditFooter,
		exact: true,
	},
	{
		path: '/edit-content/forms',
		name: 'Contenido de formularios',
		component: EditInsuranceForms,
		exact: true,
	},
	{
		path: '/edit-content/icons',
		name: 'Iconos para CMS',
		component: EditIcons,
		exact: true,
	},
	{
		path: '/edit-content/socials',
		name: 'Redes Sociales',
		component: EditSocials,
		exact: true,
	},
	{
		path: '/formsSubmitions',
		name: 'Formularios',
		component: FormsSubmitions,
		exact: true,
	},
	{
		path: '/contactUsSubmitions',
		name: 'Formularios de contacto',
		component: ContactUsSubmitions,
		exact: true,
	},
];

export default routes;
